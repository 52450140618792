import { Fragment, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";

// Icons
import AWSLogo from "../../assets/svg/aws-logo.svg";
import MicrosoftPartnerLogo from "../../assets/svg/microsoft-solutions-partner.svg";

import {
  Bars3Icon,
  FingerPrintIcon,
  WrenchScrewdriverIcon,
  //CloudIcon,
  ChevronRightIcon,
  // ServerStackIcon,
  //ServerIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";

import ravlLogo from "../../assets/images/ravl-logo.webp";

const AWSIcon = () => {
  return <img src={AWSLogo} alt="AWS Logo"></img>;
};

const MicrosoftPartnerIcon = () => {
  return <img src={MicrosoftPartnerLogo} alt="Azure Logo"></img>;
};
// Data
const services = [
  {
    label: "RAVL Advisory",
    link: "/services/advisory",
    description:
      "Our experts are here to help you with industry-leading advisory services.",
    icon: FingerPrintIcon,
  },
  {
    label: "RAVL Build",
    link: "/services/build",
    description: "We build sustainable solutions for our clients.",
    icon: WrenchScrewdriverIcon,
  },
];

const partners = [
  {
    label: "AWS",
    link: "/partners/aws",
    description: "Advanced Tier Services Partner",
    icon: AWSIcon,
  },
  {
    label: "Microsoft Azure",
    link: "/partners/microsoftazure",
    description: "Microsoft Solutions Partner",
    icon: MicrosoftPartnerIcon,
  },
  // {
  //   label: "Google",
  //   link: "/partners/google",
  //   description: "Build strategic funnels that will convert",
  //   icon: ServerStackIcon,
  // },
];

const Header = () => {
  return (
    <header className="sticky z-20 top-0 w-full bg-white">
      <nav
        className="z-20 mx-auto flex max-w-screen-2xl items-center justify-between p-4 lg:px-6"
        aria-label="Primary"
      >
        <NavLogo />
        <DesktopMenu />
        <BookACallBtn />
        <MobileMenu />
      </nav>
    </header>
  );
};

export default Header;

const NavLogo = ({ setMobileMenuOpen }) => {
  return (
    <div className="flex lg:flex-1">
      <Link
        to="/"
        className="-m-1.5 p-1.5"
        onClick={() => setMobileMenuOpen && setMobileMenuOpen(false)}
      >
        <span className="sr-only">RAVL</span>
        <img className="h-12 sm:h-14 w-auto" src={ravlLogo} alt="RAVL logo" />
      </Link>
    </div>
  );
};

const BookACallBtn = () => {
  return (
    <div className="mr-6 flex flex-1 justify-end lg:mr-0">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://outlook.office365.com/owa/calendar/ChatwithRavl@ravl.io/bookings/"
        className="btn-primary"
      >
        Book A Call
      </a>
    </div>
  );
};

const MobileSubMenu = ({
  id,
  label,
  data,
  setMobileMenuOpen,
  openSubMenu,
  setOpenSubMenu,
}) => {
  const isOpen = openSubMenu === id;

  const handleClick = () => {
    if (isOpen) {
      setOpenSubMenu(null);
    } else {
      setOpenSubMenu(id);
    }
  };

  const controls = useAnimation();

  useEffect(() => {
    if (isOpen) {
      controls.start("open");
    } else {
      controls.start("initial");
    }
  }, [isOpen, controls]);

  return (
    <div>
      <motion.button
        onClick={handleClick}
        className="flex gap-2 items-center -mx-3 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 w-full text-start"
        onHoverStart={() => !isOpen && controls.start("hover")}
        onHoverEnd={() => !isOpen && controls.start("initial")}
        whileHover={{ color: "#4C1D95" }} // color code for purple-700 in Tailwind CSS
        transition={{ duration: 0.2 }}
      >
        {label}
        <motion.div
          animate={controls}
          variants={{
            hover: { rotate: 90 },
            initial: { rotate: 0 },
            open: { rotate: 90 },
          }}
        >
          <ChevronRightIcon className="h-4 w-4" />
        </motion.div>
      </motion.button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            {[...data].map((item) => (
              <NavLink
                key={item.label}
                to={item.link}
                onClick={() => setMobileMenuOpen(false)}
                className={({ isActive }) =>
                  isActive
                    ? "block rounded-lg py-2 pl-6 pr-3 text-sm leading-7 hover:bg-gray-50 text-purple-700 font-bold"
                    : "block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                }
              >
                {item.label}
              </NavLink>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const MotionLink = motion(NavLink);

const MobileMenuItem = ({ label, link, setMobileMenuOpen }) => {
  return (
    <MotionLink
      to={link}
      onClick={() => setMobileMenuOpen(false)}
      className={({ isActive }) =>
        isActive
          ? "flex gap-2 items-center -mx-3 rounded-lg px-3 py-2 text-base leading-7 hover:bg-gray-50 text-purple-700 font-bold"
          : "flex gap-2 items-center -mx-3 rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
      }
      // activeClassName="underline"
      whileHover="hover"
      variants={{
        hover: {
          color: "#4C1D95", // color code for purple-700 in Tailwind CSS
        },
      }}
      transition={{ duration: 0.2 }}
    >
      {label}
      <motion.div
        variants={{
          hover: { x: 5 },
          initial: { x: 0 },
        }}
      >
        <ChevronRightIcon className="h-4 w-4" />
      </motion.div>
    </MotionLink>
  );
};

const MobileMenu = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleClose = () => {
    setMobileMenuOpen(false);
    setOpenSubMenu(null);
  };

  return (
    <div>
      <div className="flex lg:hidden">
        <button
          type="button"
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open navigation menu</span>
          {mobileMenuOpen ? (
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          ) : (
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          )}
          {/* <Bars3Icon className="h-6 w-6" aria-hidden="true" /> */}
        </button>
      </div>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={handleClose}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white p-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-end h-12 sm:h-14">
            <div className="sm:hidden">
              <NavLogo setMobileMenuOpen={setMobileMenuOpen} />
            </div>
            <BookACallBtn />
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => handleClose()}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <MobileSubMenu
                  id="services"
                  label="Our Services"
                  data={services}
                  setMobileMenuOpen={handleClose}
                  openSubMenu={openSubMenu}
                  setOpenSubMenu={setOpenSubMenu}
                />
                <MobileSubMenu
                  id="partners"
                  label="Our Partners"
                  data={partners}
                  setMobileMenuOpen={handleClose}
                  openSubMenu={openSubMenu}
                  setOpenSubMenu={setOpenSubMenu}
                />
                <MobileMenuItem
                  label={"Who We Are"}
                  link={"/about-us"}
                  setMobileMenuOpen={handleClose}
                />
                <MobileMenuItem
                  label={"Careers"}
                  link={"/careers"}
                  setMobileMenuOpen={handleClose}
                />
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
};

const DesktopPopover = ({ label, data }) => {
  return (
    <Popover className="relative">
      <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        {label}
        <ChevronDownIcon
          className="h-5 w-5 flex-none text-gray-400"
          aria-hidden="true"
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
          <div className="p-4">
            {data.map((item) => (
              <div
                key={item.label}
                className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
              >
                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                  <item.icon
                    className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex-auto">
                  <Popover.Button
                    as={Link}
                    to={item.link}
                    className="block font-semibold text-gray-900"
                  >
                    {item.label}
                    <span className="absolute inset-0" />
                  </Popover.Button>

                  <p className="mt-1 text-gray-600">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

const DesktopMenu = () => {
  return (
    <Popover.Group className="hidden lg:flex lg:gap-x-12">
      <DesktopPopover label="Our Services" data={services} />
      <DesktopPopover label="Our Partners" data={partners} />
      <Link
        to="/about-us"
        className="text-sm font-semibold leading-6 text-gray-900"
      >
        About Us
      </Link>
      <Link
        to="/careers"
        className="text-sm font-semibold leading-6 text-gray-900"
      >
        Careers
      </Link>
    </Popover.Group>
  );
};
